import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/xoxo/Projects/blog/put-money-values/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const FeedItems = makeShortcode("FeedItems");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "where-cancel-culture-meets-the-corporate-world"
    }}>{`Where cancel culture meets the corporate world`}</h1>
    <FeedItems filterByTags={['woke']} mdxType="FeedItems" />
    <p><a parentName="p" {...{
        "href": "http://thebrainsyouwerebornwith.com/2020/09/22/woke-beyond-repair/"
      }}>{`If you want to tell the world how woke you are, how you follow the herd and how little truth matters to you, capitalize “black.”
But if you respect blacks, respect the truth and respect yourself, don’t.`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      